import { NavBar } from 'antd-mobile';
import classNames from 'classnames/bind';
import BottomTabs from 'components/Layout/BottomTabs';
import Layout from 'components/Layout/Layout';
import { useRouter } from 'next/router';

import styles from './PageLayout.module.css';

const cx = classNames.bind(styles);

interface PageLayout {
  navComponent?: React.ReactNode;
  title?: string;
  children: React.ReactNode;
  customTitle?: React.ReactNode;
  hasBottomTab?: boolean;
  onGoBack?: () => void;
  right?: React.ReactNode;
}

function PageLayout({
  navComponent,
  title,
  children,
  onGoBack,
  customTitle,
  hasBottomTab = true,
  right,
}: PageLayout) {
  const router = useRouter();
  const { asPath } = router;

  const path = asPath.split('/')[1];

  return (
    <Layout title={title}>
      <div className={cx('container')}>
        <nav className={cx('top-container')}>
          <div className={cx(['top', path])}>
            {navComponent ? (
              navComponent
            ) : (
              <NavBar
                {...(onGoBack ? { onBack: onGoBack } : { back: null })}
                {...(right && { right })}
              >
                {customTitle || <span>{title}</span>}
              </NavBar>
            )}
          </div>
        </nav>
        <div className={cx('body-container')}>
          <div className={cx('body')}>{children}</div>
        </div>
        {hasBottomTab && (
          <div className={cx('bottom-container')}>
            <div className={cx('bottom')}>
              <BottomTabs />
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default PageLayout;
