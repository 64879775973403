import { Result } from 'antd-mobile';
import React from 'react';
import PageLayout from 'components/Layout/PageLayout';

export default function NotFound() {
  // Opinionated: do not record an exception in Sentry for 404
  return (
    <PageLayout>
      <Result
        status="error"
        title="404"
        description="존재하지 않는 페이지이에요."
      />
    </PageLayout>
  );
}
