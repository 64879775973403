import { useRouter } from 'next/router';
import { Image, TabBar } from 'antd-mobile';
import classNames from 'classnames/bind';
import styles from './BottomTabs.module.css';
const cx = classNames.bind(styles);

interface BottomTabsProps {}

function BottomTabs({}: BottomTabsProps) {
  const router = useRouter();
  const tabs = [
    {
      key: '/home',
      title: '홈',
      icon: (active: boolean) => (
        <Image
          src={'/img/home-icon.svg'}
          width={'20px'}
          alt="home-icon"
          lazy
          className={cx({ 'active-icon': active })}
        />
      ),
    },
    {
      key: '/coupon',
      title: '쿠폰/선물',
      icon: (active: boolean) => (
        <Image
          src={'/img/coupon-icon.svg'}
          width={'20px'}
          alt="coupon-icon"
          lazy
          className={cx({ 'active-icon': active })}
        />
      ),
    },
    {
      key: '/order',
      title: '주문하기',
      icon: (active: boolean) => (
        <Image
          src={'/img/order-icon.svg'}
          width={'20px'}
          alt="order-icon"
          lazy
          className={cx({ 'active-icon': active })}
        />
      ),
    },
    {
      key: '/me',
      title: '내 정보',
      icon: (active: boolean) => (
        <Image
          src={'/img/me-icon.svg'}
          width={'20px'}
          alt="me-icon"
          lazy
          className={cx({ 'active-icon': active })}
        />
      ),
    },
    {
      key: '/others',
      title: '기타',
      icon: (active: boolean) => (
        <Image
          src={'/img/others-icon.svg'}
          width={'20px'}
          alt="others-icon"
          lazy
          className={cx({ 'active-icon': active })}
        />
      ),
    },
  ];

  const handlePathChange = (value: string) => {
    return router.push(value);
  };

  return (
    <TabBar
      activeKey={router.pathname}
      onChange={handlePathChange}
      className={cx('tab-bar-container')}
    >
      {tabs.map((item) => (
        <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
      ))}
    </TabBar>
  );
}

export default BottomTabs;
